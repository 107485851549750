// Sentry configuration
import * as Sentry from "@sentry/browser";

const replay = Sentry.replayIntegration({
  maskAllText: true,
  blockAllMedia: false,
  maskAllInputs: true,
});

let tracesSampleRate = 0;

if (window.SENTRY_ENV === "production") {
  tracesSampleRate = 0.15
} else if (window.SENTRY_ENV === "testing") {
  tracesSampleRate = 1.0
}

Sentry.init({
  debug: false,
  dsn: process.env.SENTRY_JS_DSN,
  maxBreadcrumbs: 20,
  environment: window.SENTRY_ENV,
  ignoreErrors: [/ChunkLoadError/],
  enabled: (window.SENTRY_ENV === "production" || window.SENTRY_ENV === "testing"),
  allowUrls: [
    /https?:\/\/\w+\.ofri\.(ch|it)/
  ],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
  tracesSampleRate: tracesSampleRate,
  // NOTE: the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  profilesSampleRate: 0.5,
  tracePropagationTargets: [
    /https:\/\/www\.ofri\.(ch|it)/,
    /^\//
  ],
  integrations: [
    replay,
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
  ],
  beforeSend(event) {
   const id = replay.getReplayId()

    // if we're recording - flush session first
    if (id) {
      return replay
        .flush()
        .then(() => {
          return event;
        })
        .catch(() => {
          return event;
        });
    } else {
      return event;
    }
  }
});

document.addEventListener("DOMContentLoaded", () => {
  const url = new URL(window.location.href);

  if (url.pathname.includes("show_for_tradesman")) {
    // NOTE: trying to capture issues for:
    // https://ofri-eu.sentry.io/issues/4328733/events/latest/?project=4507258550943824&statsPeriod=14d&stream_index=0
    replay.startBuffering();
  }
});
